import React, { ReactElement, useMemo, useRef } from 'react';
import { Link, graphql } from 'gatsby';

import Box from '@material-ui/core/Box';

import TextField from '../components/util/TextField';
import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Tag from '../components/tag';
import useQueryParam from '../hooks/useQueryParam';

import './styles/index.css';

interface IndexProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            date: string;
            description: string;
            tags: string;
          };
          excerpt: string;
          fields: {
            slug: string;
          };
        };
      }[];
    };
  };
}

const includesInsensitive = (text: string, substr: string) =>
  text.trim().toLowerCase().includes(substr.toLowerCase().trim());

export const BlogIndex = ({ data }: IndexProps): ReactElement => {
  const posts = useMemo(() => data.allMarkdownRemark.edges, [
    data.allMarkdownRemark.edges,
  ]);

  const [search, setSearch] = useQueryParam('q', '');

  const searchInput = useRef<HTMLInputElement>(null);

  const filteredPosts = useMemo(() => {
    if (search.trim() === '') return posts;

    return posts.filter(
      ({
        node: {
          frontmatter: { title, description, tags },
        },
      }) => {
        return (
          includesInsensitive(description, search) ||
          includesInsensitive(title, search) ||
          tags.split(',').some(tag => includesInsensitive(tag, search))
        );
      }
    );
  }, [search, posts]);

  const searchTag = (tag: string) => {
    setSearch(tag);
    searchInput?.current?.focus();
  };

  return (
    <Layout>
      <SEO title="All posts" />
      <Bio />
      <TextField
        value={search}
        onChange={setSearch}
        placeholder="Search"
        autoFocus
        ref={searchInput}
      />
      <Box mt={3}>
        {filteredPosts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          const tags = node.frontmatter.tags.split(',');

          return (
            <article key={node.fields.slug}>
              <header>
                <h3 className="font-bold text-2xl mb-2">
                  <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                  className="mb-4"
                />
                <div className="flex mb-8">
                  {tags.map(tag => (
                    <Tag
                      key={tag}
                      text={tag}
                      className="mr-2"
                      onClick={() => searchTag(tag)}
                    />
                  ))}
                </div>
              </section>
            </article>
          );
        })}
      </Box>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
