import React, { ReactElement } from 'react';

interface TagProps {
  text: string;
  className?: string;
  onClick: () => void;
}

const Tag = ({ text, className = '', onClick }: TagProps): ReactElement => (
  <div className={`rounded-md bg-gray-400 text-gray-800 p-1 cursor-pointer ${className}`} onClick={onClick}>
    {text}
  </div>
);

export default Tag;
